import { Component, toChildArray, createRef, render, cloneElement, createElement, Fragment} from "preact";
import { PureComponent, createPortal } from 'preact/compat';

import { toVdom } from "../helpers";

import { connect } from 'react-redux';
import windowInfo from "../../../window-info"
import ResizeCard from "../resize-card";
import SlideshowEditor from '../../../overlay/slideshow-editor';
import { diffProps } from 'node_modules!preact/src/diff/props.js';
import register from "../../register"
import withThumbnailContent from "../thumbnail-index-generator";
import { subscribe, unsubscribe, dispatch } from '../../../../customEvents';

import { withScroll } from "../../scroll-element";
import { withPageInfo } from "../../page-info-context";
import * as helpers from "@cargo/common/helpers";

import { UsesHost } from "../../uses/uses"

import _ from 'lodash';

let resizeObserver;


const layoutData = {
	name: 'slideshow',
	displayName: 'Slideshow',
	tagName: 'GALLERY-SLIDESHOW',
	disabledMediaItemOptions: ['scale', 'sticker', 'limit-by'],
	mobileOptions: [
		{
			labelName: "",
			name: "mobile-transition-type",
			type: "radio",
			value: "slide",
			values: [
				{
					labelName: 'Fade',
					value: 'fade'
				},
				{
					labelName: 'Slide',
					value: 'slide'
				},
				{
					labelName: 'Scrub',
					value: 'scrub'
				},
			]
		},
		{
			labelName: "Transition Speed",
			name: "mobile-transition-speed",
			type: "scrubber",
			numberOnlyMode: true,
			value: 0.8,
			min: 0,
			max: 10,
			step: 0.1,
			requirements: [
				{
					option: 'transition-type',
					shouldBe: (val)=>{
						return val === 'slide' || val === 'fade'
					}
				}
			]			
		},
		{
			type: 'group',
			className: 'grid-columns-even',
			children: [
				{
					labelName: "Navigation",
					name: "mobile-navigation",
					type: "check-box",
					value: true,
				},				
				{
					labelName: "Captions",
					name: "mobile-show-captions",
					type: "check-box",
					value: true,
				},
			]
		},	
		{
			labelName: "Autoplay",
			name: "mobile-autoplay",
			type: "check-box",
			value: true,
		},
		{
			labelName: "Autoplay Delay",
			name: "mobile-autoplay-delay",
		 	type: "scrubber",
		 	value: 3,
		 	step: 0.1,
		 	min: 0.1,
		 	max: 30,
		 	numberOnlyMode: true,
			requirements: [
				{
					option: 'autoplay',
					shouldBe: true
				}
			]			 	
		},			

		{
			type: 'spacer',
		},

		{
			type: 'group',
			className: 'slideshow-size-group grid-columns-template-1-auto',
			children: [
				// width scale
				{
					type: 'custom',
					name: 'widthTextLabel',
					requirements: [
						{
							option: 'mobile-limit-by',
							shouldBe: 'width'
						}
					]														
				},

				// height scale
				{
					name:"mobile-scale",
					value: '50rem',

					allowCalc:true,
					allowVar:true,
					type:'scrubber',
					allowOutOfRangeTextInput:false,
					labelName:"Scale: Height",
					allowedUnits:['px', '%', 'rem', 'em', 'vmin','vmax', 'vw', 'vh'],
					min:{
						'px': 8,
						'rem': 0.5,
						'em': 0.5,
						'etc': 1,	
					},
					max:{
						'%': 100,
						'vmin': 100,
						'vmax': 100,
						'vh': 100,
						'vw': 100,
						'etc': undefined
					},
					addDefaultUnitToUnitlessNumber:true,
					defaultUnit:"rem",

					requirements: [
						{
							option: 'mobile-limit-by',
							shouldBe: 'height'
						}
					]							
				},

				// fit scale
				{
					type: 'custom',
					name: 'fitTextLabel',
					requirements: [
						{
							option: 'mobile-limit-by',
							shouldBe: 'fit'
						}
					]													
				},
				{
					name: "mobile-limit-by",
					type: "radio",
					value: "width",
					values: [
						{
							name: 'width',
							customLabel: 'widthLabel',
							value: 'width'
						},
						{
							name: 'height',
							customLabel: 'heightLabel',
							value: 'height'
						},
						{
							name: 'fit',
							customLabel: 'fitLabel',
							value: 'fit'
						},
					],	
				}
			]
			
		},


		{
			labelName: "Horizontal Align",
			name: "mobile-horizontal-align",
			type: "radio",
			value: "center",
			values: [
				{
					labelName: 'Left',
					iconName: 'alignContentLeft',
					value: 'left'
				},
				{
					labelName: 'Center',
					iconName: 'alignContentCenter',
					value: 'center'
				},
				{
					labelName: 'Right',
					iconName: 'alignContentRight',
					value: 'right'
				},
			],
		},
		{
			labelName: "Vertical Align",
			name: "mobile-vertical-align",
			type: "radio",
			value: "center",
			values: [
				{
					labelName: 'Top',
					iconName: 'alignContentTop',
					value: 'top'
				},
				{
					labelName: 'Middle',
					iconName: 'alignContentMiddle',
					value: 'center'
				},
				{
					labelName: 'Bottom',
					iconName: 'alignContentBottom',
					value: 'bottom'
				},
			]		
		},
			
		{
			labelName: "Shuffle",
			name: "mobile-shuffle",
			type: "check-box",
			value: false,
		},

		{
			type: 'more-actions',
			children: [
				{
					labelName: "Slides",
					name: "toggle-thumbs",
					type: "button",
					className: 'text-button remove',
					onClick: function(e){
						e.preventDefault();
						if ( this.state.activeGallery.thumbnailsVisible ){
							this.state.activeGallery.hideThumbnails();
						} else {
							this.state.activeGallery.showThumbnails();
						}
					}
				}				
			]
		}

	],
	options: [
		{
			labelName: "",
			name: "transition-type",
			type: "radio",
			value: "slide",
			values: [
				{
					labelName: 'Fade',
					value: 'fade'
				},
				{
					labelName: 'Slide',
					value: 'slide'
				},
				{
					labelName: 'Scrub',
					value: 'scrub'
				},
			]
		},
		{
			labelName: "Transition Speed",
			name: "transition-speed",
			type: "scrubber",
			numberOnlyMode: true,
			value: 0.8,
			min: 0,
			max: 10,
			step: 0.1,
			requirements: [
				{
					option: 'transition-type',
					shouldBe: (val)=>{
						return val === 'slide' || val === 'fade'
					}
				}
			]			
		},
		{
			type: 'group',
			className: 'grid-columns-even',
			children: [
				{
					labelName: "Navigation",
					name: "navigation",
					type: "check-box",
					value: true,
				},				
				{
					labelName: "Captions",
					name: "show-captions",
					type: "check-box",
					value: true,
				},
			]
		},	
		{
			labelName: "Autoplay",
			name: "autoplay",
			type: "check-box",
			value: true,
		},
		{
			labelName: "Autoplay Delay",
			name: "autoplay-delay",
		 	type: "scrubber",
		 	value: 3,
		 	step: 0.1,
		 	min: 0.1,
		 	max: 30,
		 	numberOnlyMode: true,
			requirements: [
				{
					option: 'autoplay',
					shouldBe: true
				}
			]			 	
		},
		{
			labelName: "Pause On Hover",
			name: "pause-on-hover",
			type: "check-box",
			value: false,
			requirements: [
				{
					option: 'autoplay',
					shouldBe: true
				}
			]			
		},		
		{
			type: 'spacer',
		},


		{
			type: 'group',
			className: 'slideshow-size-group grid-columns-template-1-auto',
			children: [
				// width scale
				{
					type: 'custom',
					name: 'widthTextLabel',
					requirements: [
						{
							option: 'limit-by',
							shouldBe: 'width'
						}
					]													
				},

				// height scale
				{
					name:"scale",
					value: '50rem',

					allowCalc:true,
					allowVar:true,
					type:'scrubber',
					allowOutOfRangeTextInput:false,
					labelName:"Scale: Height",
					allowedUnits:['px', '%', 'rem', 'em', 'vmin','vmax', 'vw', 'vh'],
					min:{
						'px': 8,
						'rem': 0.5,
						'em': 0.5,
						'etc': 1,	
					},
					max:{
						'%': 100,
						'vmin': 100,
						'vmax': 100,
						'vh': 100,
						'vw': 100,
						'etc': undefined
					},
					addDefaultUnitToUnitlessNumber:true,
					defaultUnit:"rem",

					requirements: [
						{
							option: 'limit-by',
							shouldBe: 'height'
						}
					]							
				},

				// fit scale
				{
					type: 'custom',
					name: 'fitTextLabel',
					requirements: [
						{
							option: 'limit-by',
							shouldBe: 'fit'
						}
					]							
				},
				{
					name: "limit-by",
					type: "radio",
					value: "width",
					values: [
						{
							name: 'width',
							customLabel: 'widthLabel',
							value: 'width'
						},
						{
							name: 'height',
							customLabel: 'heightLabel',
							value: 'height'
						},
						{
							name: 'fit',
							customLabel: 'fitLabel',
							value: 'fit'
						},
					],	
				}
			]
			
		},



		{
			labelName: "Horizontal Align",
			name: "horizontal-align",
			type: "radio",
			value: "center",
			values: [
				{
					labelName: 'Left',
					iconName: 'alignContentLeft',
					value: 'left'
				},
				{
					labelName: 'Center',
					iconName: 'alignContentCenter',
					value: 'center'
				},
				{
					labelName: 'Right',
					iconName: 'alignContentRight',
					value: 'right'
				},
			],
		},
		{
			labelName: "Vertical Align",
			name: "vertical-align",
			type: "radio",
			value: "center",
			values: [
				{
					labelName: 'Top',
					iconName: 'alignContentTop',
					value: 'top'
				},
				{
					labelName: 'Middle',
					iconName: 'alignContentMiddle',
					value: 'center'
				},
				{
					labelName: 'Bottom',
					iconName: 'alignContentBottom',
					value: 'bottom'
				},
			]		
		},
		{
			type: 'group',
			className: 'grid-columns-even',
					children: [		
				{
					type: 'group',
					className: 'grid-columns-even',
					children: [
						{
							labelName: "Disable Zoom",
							name: "disable-zoom",
							type: "check-box",
							value: false,
						},				
						{
							labelName: "Shuffle",
							name: "shuffle",
							type: "check-box",
							value: false,
						},
					]
				},					
			]
		},
		{
			labelName: "Disable Scroll Transition",
			name: "disable-scroll-animation",
			type: "check-box",
			value: false,
			requirements: [
				{
					option: 'imageSettingsScrollAnimation',
					shouldBe: true
				}
			]
		},	
		// deprecated/hidden options
		{
			name: 'auto-height',
			value: 'custom',
		},
		{
			name: 'height-limit',
			value: '60%',
		},
		{	
			name: 'adjust-for-captions',
			value: false,
		},

		{
			type: 'more-actions',
			children: [
				{
					labelName: "Slides",
					name: "toggle-thumbs",
					type: "button",
					className: 'text-button remove',
					onClick: function(e){
						e.preventDefault();
						if ( this.state.activeGallery.thumbnailsVisible ){
							this.state.activeGallery.hideThumbnails();
						} else {
							this.state.activeGallery.showThumbnails();
						}
					}
				}				
			]
		}
	]
}

layoutData.defaults = helpers.collapseOptions(layoutData.options);

layoutData.mobileDefaults = helpers.collapseOptions(layoutData.mobileOptions);

// percentage of the slide to move before we trigger transition to next one while holding
// 0.1 === 10%
// if 0, then we divide slideshow width by slide number, so scrubbing width of slideshow scrubs entire slideshow
const SCRUB_MOVE_THRESHOLD = .04;

// top-speed expressed as in percentage of threshold we can move in a single frame
// 0.1 === 10%;
const SCRUB_TOP_SPEED = .25

// the amount by which scrub speed decelerates, per frame
const SCRUB_SPEED_DECAY = 0.085;

// the amount by which the speed accelerates, per frame
const SCRUB_SPEED_ACCEL = 0.1;

// percentage of the slide to move before we trigger transition to next one on release
const SLIDE_TRANSITION_THRESHOLD = 0.08;

// number of pixels before we start to register pointer-down-movement as drag
const DRAG_THRESHOLD = 2;

// number of adjacent slides to render & load
const LAZYLOAD_SLIDES = 3;

// number of adjacent slides to lazyload when using scrub
const LAZYLOAD_SCRUB_SLIDES = 15;




// scrub Statuses
const SCRUB_DECAYING = 0;
const SCRUB_NEXT = 1;
const SCRUB_PREV = 2;

class Slideshow extends PureComponent {

	constructor(props){
		super(props);

		const defaultViewPortIntersection = !helpers.isServer ? { hasLayout: true, visible: true, position: 'inside' } : { hasLayout: false, visible: false, position: 'unknown' };

		this.state = {

			captionHeightMap: [],

			showThumbnails: this.props.thumbnails === true || this.props.thumbnails === 'true',

			// once all of the first round of visible slides have loaded, a 'loaded' class is added
			hasLoaded: helpers.isServer,

			visible: false,

			viewportIntersection: defaultViewPortIntersection,

			layoutIncrement: 0,

			pointerOver: false,
			initialShowNavigation: true,
			
			destinationIndex: 0,
			activeIndex: 0,
			initialActiveIndex: 0,


			// while slide is in navigation-triggered transition or snapping to nearest slide
			inTransition: false,

			elWidth: 0,
			hasSizes: {
				slideHeightPx: false,
				elWidth: false,
			},
			slideHeightPx: 0,

			tallestSlide: {
				index: 0,
				height: 0
			},

			...this.getNavigation(),

			// pointer status
			isDragging: false,
			isPointerDown: false,


			// the xTransform value, calculated continuously while dragging or animating
			// xTransform: 0,


			scrubAnimating: false,
			scrubStatus: SCRUB_DECAYING

		}

		this.loadedItems = new Set();

		// this is used to shuffle psuedo-randomly
		this.shuffleSeed = Math.random()*100;		

		// tracking the last couple of pointer movements frame-by-frame for scrub motion
		this.lastPointerFrameDeltaX = 0;
		this.pointerFrameDeltaX = 0;

		// use this to track the number of frames since last 'scrub'
		this.scrubCounter = 0;		

		// last pointerX captured on frame animation
		this.lastPointerX = 0;

		// initial pointerX on initial pointer-down
		// scrub transition resets this whenever slide is progressed
		this.firstPointerX = 0;

		// current pointerX while pointer-down
		this.pointerX = 0;

		// the transformation when a slide -slideshow is being dragged or transitioning
		this.xTransform = 0;

		// slideshow list
		this.slideListRef = createRef();

		// animation callbacks
		this.scrubAnimationRequest = null;

		// autoplay progression
		this.autoplayInterval = null;

		this.updateOptions();

		// hold the timestamp for the last value when animating the slide
		this.lastTimestamp = 0;
		
		this.styleRef = createRef();

		// make some methods available on the element itself

		Object.defineProperty(this.props.baseNode, 'thumbnailsVisible', {
			get: ()=> { return this.state.showThumbnails },
			set: (val)=>{ return this.setState({ showThumbnails: !!val})},
			configurable: true
		});

		this.props.baseNode.pause = this.pause;
		this.props.baseNode.resume = this.startAutoplay;
		this.props.baseNode.nextSlide = this.nextSlide;
		this.props.baseNode.prevSlide = this.prevSlide;
		this.props.baseNode.showThumbnails = this.showThumbnails;
		this.props.baseNode.hideThumbnails = this.hideThumbnails;
		this.props.baseNode.goToSlide = this.goToSlide;
		this.props.baseNode.getActiveSlide = this.getActiveSlide;
		this.props.baseNode.updateNavigation = ()=>{

			const {
				prevNavigation,
				nextNavigation
			} = this.getNavigation();

			if (
				this.state.prevNavigation !== prevNavigation ||
				this.state.nextNavigation !== nextNavigation 
			){
				this.setState({nextNavigation, prevNavigation});
			}
			
		};

	}

	render(props, state){
		this.updateOptions();

		const {
			baseNode,
			adminMode,
			pageInfo,
			usesScrollAnimation,
		} = props;

		const {
			showThumbnails,
			activeIndex,
			destinationIndex,
			inTransition,
			isPointerDown,
			scrubAnimating,
			hasLoaded,
			pointerOver,
			hasSizes,
			initialShowNavigation,
			captionHeightMap,
			viewportIntersection,
		} = state;

		const {
			showCaptions,
			autoPlayDelay,
			limitBy,
			adjustForCaptions
		} = this.options;

		let {
			elWidth
		} = state;

		if( !hasSizes.elWidth ){
			this.cachedElWidth = this.props.baseNode.offsetWidth;
			elWidth = this.cachedElWidth;
		}


		let slideHeightCSS = limitBy==='fit' ? 'var(--fit-height)' : helpers.getCSSValueAndUnit(this.options.scale, limitBy == 'width' ? '%' : 'rem').join('');

		const slides = this.renderSlides();

		let slideListStyles = this.getSlideListStyles();		

		const container = <div
				ref={this.slideListRef}
				style={{
					transform: slideListStyles.transform,
				}}
				className="slide-list"
				draggable={false}
	 			tabIndex={0}

			>
				{slides}
			</div>		

		const wrapperAttributes = {}

		if( !showThumbnails) {
			wrapperAttributes.onKeyDown = this.handleKeyDown;
			wrapperAttributes.onClickCapture = this.onClickCapture;
			wrapperAttributes.className = 'slideshow-wrapper';

			if ( inTransition ) {
				wrapperAttributes.className+=' in-transition';
			}


			if ( isPointerDown ) {
				wrapperAttributes.className+=' pointer-down';
			}

			if ( scrubAnimating && this.isScrub ){
				wrapperAttributes.className+=' scrubbing';
			}
		}

		let slideHeightPx = limitBy==='width' ? this.state.tallestSlide.height : this.state.slideHeightPx;

		const mediaItemAttributes = this.makeMediaItemAttributes();

		let shadowStyleMap = this.mediaItems.map((el, index)=>{
			const slot = mediaItemAttributes[index].slot;

			const size = el._size || {
				width: 0,
				height: 0,
				mediaSize: {
					width: 0,
					height: 0,
					padSize: 0,
				},
				mediaItemSize: {
					width: 0,
					height: 0,
					padSize: 0,
				}					
			};

			let figureWidth = '100%'
			let width = size.width;
			let height = size.height;
			let customSlideHeight = null;

			let captionHeight = this.options.showCaptions ? (this.state.captionHeightMap[index] || 0) : 0;

			if (!showThumbnails){

				if( typeof width == "string" && width.indexOf('%') > -1 ){

					figureWidth = width;

				} else if( limitBy !=='width' ){

					if( adjustForCaptions ){
						customSlideHeight = slideHeightPx +- captionHeight;
					} else {
						customSlideHeight = slideHeightPx;
					}

					const imageRatio = height/width;
					let imgHeight = (customSlideHeight+-size.mediaItemSize.padSize +-size.mediaSize.padSize)
					let imgWidth = imgHeight*(1/imageRatio);

					const borderRatio = (imgHeight+size.mediaSize.padSize)/(imgWidth+size.mediaSize.padSize)
					let widthInsideSlide = (customSlideHeight+-size.mediaItemSize.padSize)*(1/borderRatio);

					// fudge this a liiitle bit so that we don't have single pixels hanging between otherwise matching images
					if( Math.abs((widthInsideSlide+size.mediaItemSize.padSize ) - elWidth ) < 1 ){
						figureWidth = '100%'
					} else {
						figureWidth = `${Math.min(100, 100*(widthInsideSlide+size.mediaItemSize.padSize )/(elWidth))}%`	
					}
				}
			} else {
				figureWidth = null;
			}

			return {
				captionHeight: captionHeight,
				customSlideHeight,
				figureWidth,
				slot
			}
		})

		const captionTransitionDuration = Math.min(this.options.speed/2, 0.1)		
		const slideModeColumns = Math.max(Math.floor(elWidth/200), 2);

		this.setMediaElementProperties(mediaItemAttributes);


		let usesProps = usesScrollAnimation && !this.props['disable-scroll-animation'] ? ' scroll-transition' : '';

		return <>
			{createPortal(
				<Fragment key="slideshow-fragment">
				<UsesHost
					uses={usesProps}
					key="slideshow-host"
					visibility={ viewportIntersection }
					onLazyLoadChange={ null }
					adminMode={adminMode}
					customElementMode={true}
					baseNode={baseNode}
				>
				{this.options.showCaptions ? <style id="gallery-slideshow-captions" ref={this.styleRef}>{`
					::slotted(media-item) {
						--slideshow-caption-transform: ${slideListStyles['--slideshow-caption-transform'] || 'translateX(0px)'};
						z-index: 9;
					}
				`}</style> : null}		
				<style id="gallery-slideshow">{`
* {
	box-sizing: border-box;
}

:host([hidden]) {
	display: none;
}

:host {
	position: relative;
	transition-timing-function: ease-in-out;
    transition-property: opacity;
    transition-duration: .2s;
	opacity: ${(hasLoaded || showThumbnails) ? 1 : 0};

	width: var(--resize-parent-width, 100%);
	max-width: 100%;
    --navigation-opacity: ${(pointerOver || initialShowNavigation) ? 1 : 0};
    --slide-height: ${slideHeightPx}px;

	--slideshow-horizontal-align: ${
		this.options.hAlign === 'left' ? 'flex-start' : 
		this.options.hAlign === 'right' ? 'flex-end' :
		'center'
	};

	--slideshow-caption-align: ${
		this.options.hAlign === 'left' ? 'left' : 
		this.options.hAlign === 'right' ? 'right' :
		'center'
	};	

	--slideshow-vertical-align: ${
		this.options.vAlign === 'top' ? 'flex-start' : 
		this.options.vAlign === 'bottom' ? 'flex-end' : 
		'center'
	};

    ${showThumbnails ?`
	    display: grid;
		grid-template-columns: repeat(${slideModeColumns}, 1fr);
		column-gap: 2rem;
		row-gap: 2rem;
		align-items: start;
		overflow:hidden;
		--display-slideshow-captions: block;
		--slideshow-caption-opacity: 1;	

	`: `
		--slideshow-caption-transition-duration: ${captionTransitionDuration}s;	
		--display-slideshow-captions: ${showCaptions ? 'block' : 'none' }!important;
	    display: flex;
    	flex-direction: column;
	`}
}

${!showThumbnails ? shadowStyleMap.map((mapItem, index)=>{
	return `
::slotted(media-item[slot="${mapItem.slot}"]){
	--item-width: ${mapItem.figureWidth};
	--slide-height: ${mapItem.customSlideHeight || slideHeightPx}px; 
	--caption-height: ${mapItem.captionHeight}px;
}`							
}).join('') : ''}

${!showThumbnails ? `
::slotted(media-item) {
    transition: none!important;
    display:block;
	min-width: ${elWidth}px;
	flex-shrink: 0;
}


	`: `
::slotted(media-item){
	min-width: 0;
	max-width: 100%;
	--item-width: 100%;	
	--resize-parent-width: 100%;
	--slide-height: auto;
}`}

.slide { 
    -webkit-user-select:none;
    user-select:none;
    flex-shrink: 0;
    width: ${elWidth}px;
}

.navigation-wrapper {

	order: 1;
	position: relative;
}

.slideshow-wrapper {
	--resize-parent-width: ${elWidth}px;
	order: 3;
	margin-bottom: ${Math.max(...this.state.captionHeightMap.filter(num=>num!== undefined))}px;
}


.slide-list {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transform:translateX(0);
    height: var(--slide-height, auto);		
    touch-action: manipulation;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
}

.slide-list:focus {
    outline: 0;
}


/*
slide transition type 
default transition is slide
*/
${
	this.isSlide ? `
.slide {
    visibility:hidden;
	--base-translate: none!important;    
}

.pointer-down .adjacent-slide.slide,
.in-transition .adjacent-slide.slide,
.active-slide.slide {
    visibility: visible;
}

.slideshow-wrapper {
	overflow-y: visible;
	overflow-x: clip;
}


`: ''
}

${
	this.isFade ? `


.slide {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    display: flex;
    z-index: 0;

    ${this.state.cancelFadeTransition ? `
	  -webkit-transition: none !important;
	  -moz-transition: none !important;
	  -o-transition: none !important;
	  transition: none !important;
    `: `
		transition-timing-function: var(--slideshow-fade-transition-out, cubic-bezier(0.68, 0, 0.68, 0.19));
	    transition-property: opacity;
		transition-duration: ${this.options.speed}s;	
    `}
}

.adjacent-slide.slide {
    display: flex;
}

.active-slide.slide {
    position: relative;
    z-index: 3;
}

.destination-slide.slide {
    ${this.state.cancelFadeTransition ? '' : `transition-timing-function: var(--slideshow-fade-transition-in, cubic-bezier(0.3, 0, 0.4, 1));`}
	opacity: 1!important;
}

	`: ''
}


${
	this.isScrub ? `
.slide {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    display: flex;
    z-index: 0;
	transition: opacity .08s cubic-bezier(0.5, 0, 1, 0.5);						


    visibility: hidden;
}

.adjacent-slide.slide {
    visibility: visible;
}

.active-slide.slide {
    position: relative;
    z-index: 3;
}

.destination-slide.slide {
	transition: opacity .08s cubic-bezier(0, 0.5, 0.5, 1);
    opacity: 1!important;
}

.slide {
    cursor: ew-resize;
}
	`: ''
}

/* captions */

.slide {
	--slideshow-caption-opacity: 0;	
}

.in-transition .slide{
    --slideshow-caption-opacity: 0;
}

${!this.state.hideCaptionDuringTransition ? `
.destination-slide.active-slide.slide {
    --slideshow-caption-opacity: 1;
}
`: ''}

${
// if there's no transition, show caption while draggign
	captionTransitionDuration == 0 ? `
.destination-slide.slide,
.active-slide.slide {
    --slideshow-caption-opacity: 1;
}	
` :''}

.scrubbing .slide {
    --slideshow-caption-opacity: 0;
}

/** default navigation **/
.navigation-wrapper {

    position: absolute;
    left:0;
    right: 0;
    
    z-index: 2;
    height: var(--slide-height, auto);
    opacity: var(--navigation-opacity, 1);

}


 				`}</style>
				{adminMode && pageInfo.isEditing && !helpers.isServer && <SlideshowEditor
						{...props}
						onItemResize={this.onItemResize}
						inTransition={this.state.inTransition || this.state.isDragging}
						suppressMutation={this.state.isDragging }
						gallerySpecificAttributes={usesScrollAnimation ? ['disable-scroll-animation'] : []}
						galleryInstance={baseNode}
					/>
				}	 				
				{!showThumbnails && this.options.navigation && this.len > 1 &&
					<div className="navigation-wrapper" part={`slideshow-nav ${(pointerOver || initialShowNavigation) ? 'navigation-active':''}`}>
						{this.state.prevNavigation}
						{this.state.nextNavigation}						
					</div>
				}
				{showThumbnails ?
					<slot name='thumbnails' onClick={this.onThumbnailClick}></slot> :
					<div part="slides" {...wrapperAttributes} onPointerDown={this.onPointerDown}  >
	 					{container}
					</div>
				}
				<ResizeCard
					values={{
						slideHeightPx: slideHeightCSS,
						elWidth: '100%',
					}}
					style={showThumbnails ? {
						gridColumn: `1 / span ${slideModeColumns}`
					} : null}
					onResize={this.onResize}
				/>	
			</UsesHost>
	
		</Fragment>, this.props.baseNode.shadowRoot)}
			{this.props.children}
		</>

	}

	getNavigation = ()=>{

		const allChildren = Array.from(this.props.baseNode.children);
		let prevNavigation = allChildren.find(el=>el.getAttribute('part') =='previous-button');
		let nextNavigation = allChildren.find(el=>el.getAttribute('part') =='next-button');

		if( prevNavigation ){
			prevNavigation = toVdom(prevNavigation, null, {
 				onClick: this.prevSlide,
 				onPointerDown: (e) =>this.startScrubDelta(e, SCRUB_PREV),
 				key: 'previous-button',
 				part: 'previous-button'				
			});
		} else {
			prevNavigation = <div
				part="slideshow-nav-previous-button"			
				class="slideshow-navigation previous-button"
				onClick={this.prevSlide}
				onPointerDown={ (e) =>this.startScrubDelta(e, SCRUB_PREV) }
			>
 				<svg part="slideshow-nav-prev" x="0px" y="0px" viewBox="0 0 36 36" style="enable-background:new 0 0 36 36;">
				 	<rect part="slideshow-nav-background" width="36" height="36" rx="36"/>
					<path part="slideshow-nav-arrow" d="M22.3,28l-10-10l10-10"/>
				</svg>
			</div>
		}


		if( nextNavigation ){
			nextNavigation = toVdom(nextNavigation, null, {
 				onClick: this.nextSlide,
 				onPointerDown: (e) =>this.startScrubDelta(e, SCRUB_NEXT),
 				key: 'next-button',
 				part: 'next-button'				
			});			
		} else {
			nextNavigation = <div
				part="slideshow-nav-next-button"			 					
				class="slideshow-navigation next-button"
				onClick={this.nextSlide}
				onPointerDown={(e) =>this.startScrubDelta(e, SCRUB_NEXT)}
			>
				<svg part="slideshow-nav-next" x="0px" y="0px" viewBox="0 0 36 36" style="enable-background:new 0 0 36 36;">
					<rect part="slideshow-nav-background" width="36" height="36" rx="36"/>
					<path part="slideshow-nav-arrow" d="M22.3,28l-10-10l10-10"/>
				</svg>
			</div>
		}

		return {
			prevNavigation,
			nextNavigation
		}		
	}



	/* extends and formats certain props to be easily-usable */
	updateOptions(){
		let {
			// forcing this to be true for now
			'adjust-for-captions': adjustForCaptions = layoutData.defaults['adjust-for-captions'].value,

		    'limit-by': limitBy = layoutData.defaults['limit-by'].value,
		    'scale': scale = layoutData.defaults['scale'].value,	

		    autoplay = layoutData.defaults.autoplay.value,
		    'autoplay-delay': autoPlayDelay = layoutData.defaults['autoplay-delay'].value,
		    'transition-speed': speed = layoutData.defaults['transition-speed'].value,
		    'transition-type': type = layoutData.defaults['transition-type'].value,
		    navigation = layoutData.defaults['navigation'].value,

		    'vertical-align': vAlign = layoutData.defaults['vertical-align'].value,
		    'horizontal-align': hAlign = layoutData.defaults['horizontal-align'].value,
		    shuffle = layoutData.defaults['shuffle'].value,
		    'show-captions': showCaptions = layoutData.defaults['show-captions'].value,
		    'disable-zoom': disableZoom = layoutData.defaults['disable-zoom'].value,		
			'disable-scroll-animation': disableScrollAnimation = layoutData.defaults['disable-scroll-animation'].value,    
		    'pause-on-hover': pauseOnHover = layoutData.defaults['pause-on-hover'].value,

	    

		    'mobile-limit-by': mobileLimitBy,
		    'mobile-scale': mobileScale,		    

			'mobile-adjust-for-captions': mobileAdjustForCaptions,
			'mobile-autoplay': mobileAutoplay,
		    'mobile-autoplay-delay': mobileAutoPlayDelay,
		    'mobile-transition-speed': mobileSpeed,
		    'mobile-transition-type': mobileType,
		    'mobile-navigation': mobileNavigation,

		    'mobile-vertical-align': mobileVAlign,
		    'mobile-horizontal-align': mobileHAlign,
		    'mobile-shuffle': mobileShuffle,
		    'mobile-show-captions': mobileShowCaptions,
		    'mobile-pause-on-hover': mobilePauseOnHover,

		    // deprecated and folded into scale/limit-by
		    'auto-height': autoHeight = layoutData.defaults['auto-height'].value,
		    'height-limit': heightLimit = layoutData.defaults['height-limit'].value,		    
		    'mobile-auto-height': mobileAutoHeight,
		    'mobile-height-limit': mobileHeightLimit,

		} = this.props;



		// if we don't have limitBy and scale properties, backfill with custom-height and height-limit
		if( this.props['limit-by'] === undefined && this.props['auto-height'] !== undefined ){
			if( autoHeight === 'custom') {
				limitBy = 'height';

				if( this.props.scale == undefined && this.props['height-limit'] !== undefined ){
					scale = heightLimit;
				} 

			} else if ( autoHeight === 'fit' ){
				limitBy = 'fit';

				if( this.props.scale == undefined ){
					scale = 100;
				}
			} else {
				limitBy = 'width';
				if( this.props.scale == undefined ){
					scale = 100;
				}
				
			}
		}



		type = (type == "fade" || type == "scrub") ? type : "slide";

		const isMobile = this.props.isMobile

		const options = {
			adjustForCaptions: (isMobile && mobileAdjustForCaptions !== undefined ) ? mobileAdjustForCaptions : adjustForCaptions,
			shuffle: (isMobile && mobileShuffle !== undefined ) ? mobileShuffle : shuffle,
			autoplay: (isMobile && mobileAutoplay !== undefined ) ? mobileAutoplay: autoplay,
			autoPlayDelay: (isMobile && mobileAutoPlayDelay !== undefined ) ? mobileAutoPlayDelay : autoPlayDelay,
			speed: (isMobile && mobileSpeed !== undefined ) ? mobileSpeed : speed,
			type: (isMobile && mobileType !== undefined ) ? mobileType : type,
			navigation: (isMobile && mobileNavigation !== undefined ) ? mobileNavigation : navigation,
			disableScrollAnimation: disableScrollAnimation,
			disableZoom: disableZoom,

			limitBy: (isMobile && mobileLimitBy !== undefined ) ? mobileLimitBy : limitBy,
			scale: (isMobile && mobileScale !== undefined ) ? mobileScale : scale,

			// autoHeight: (isMobile && mobileAutoHeight !== undefined ) ? mobileAutoHeight : autoHeight,
			// heightLimit: (isMobile && mobileHeightLimit !== undefined ) ? mobileHeightLimit : heightLimit,
			vAlign: (isMobile && mobileVAlign !== undefined ) ? mobileVAlign : vAlign,
			hAlign: (isMobile && mobileHAlign !== undefined ) ? mobileHAlign : hAlign,
			showCaptions: (isMobile && mobileShowCaptions !== undefined ) ? mobileShowCaptions : showCaptions,
			pauseOnHover: (isMobile && mobilePauseOnHover !== undefined ) ? mobilePauseOnHover : pauseOnHover
		}


		if( options.speed == 0 && options.type === 'slide'){
			options.type = 'fade'
		}

		
		this.options = options;

		this.isScrub = options['type'] === 'scrub';
		this.isSlide = options['type'] === 'slide';
		this.isFade = options['type'] === 'fade';

		const allChildren = Array.from(this.props.baseNode.children);

		this.mediaItems = Array.from(this.props.baseNode.children).filter(el=>{
			return el.tagName == 'MEDIA-ITEM'
		});

		this.len = this.mediaItems.length;

		if(this.len > 0){

			this.shuffleList = [];
			if(this.options.shuffle){
				for( let i = 0; i < this.len; i++){
				 this.shuffleList.push(i);
				}
				let comparator = this.shuffleSeed;
				let increment = 0;
				this.shuffleList = this.shuffleList.sort((a) => {
				   increment++;
				   comparator = (comparator+9)%10
				   return (Math.sin(increment+2.4)*5 + 5)%10 > comparator ? -1 : 1;
				});
			}
		} else {
			this.shuffleList = [];
		}
	

		let lazyloadNext = this.isScrub ? Math.min(LAZYLOAD_SCRUB_SLIDES, (this.len-1)/2) : Math.min(LAZYLOAD_SLIDES, (this.len-1)/2);
		let lazyloadPrev = lazyloadNext;

		lazyloadPrev = Math.max(1, Math.floor(lazyloadPrev) )
		lazyloadNext = Math.max(1, Math.ceil(lazyloadNext) );

		if(this.len == 3){
			lazyloadPrev = 1;
			lazyloadNext = 1;
		} else if( this.len == 2){
			lazyloadPrev = 0;
		} else if ( this.len == 1){
			lazyloadPrev = 0;
			lazyloadNext = 0;
		}
	
		this.lazyloadPrev = lazyloadPrev;
		this.lazyloadNext = lazyloadNext;

	}

	setMediaElementProperties =(mediaItemAttributes)=>{
		if(this.len == 0){
			return;
		}		
		
		const mediaItems = [...this.mediaItems];

		mediaItems.forEach((el, index)=>{

			const attribs = mediaItemAttributes[index] || {};

			const visible = attribs.visibility || false;

			delete attribs.isAdjacent;

			delete attribs.visibility;

			if( !this.state.hasLoaded){
				attribs.itemLoad = this.onMediaLoad
			} else {
				attribs.itemLoad = null;
			}
			const props = el._props || {}
			diffProps(el, {...props, ...attribs}, props, false, false);

			el._setZoomStatus?.( this.options.disableZoom );

			if( this.props.usesScrollAnimation && !this.props['disable-scroll-animation'] && !this.options.disableScrollAnimation ){
				el._setScrollTransitionStatus?.( true )
			}
			

			// Indicate wether the media item is hidden in the slideshow carousel ("outside") or actually out of the viewport ("below" or "above")
			// this will never evauluate to "inside", but should not be slotted into the object when that value would pass anyway.
			const viewportPosition = this.state.viewportIntersection.position !== 'inside' ? this.state.viewportIntersection.position : 'outside';


			if( el.assignedSlot && !this.state.inTransition){

				// load the 
				if( !this.state.visible && this.state.lazyloadable && el.slot.replace('slot-', '') == this.state.activeIndex ){

					dispatch(el, 'lazyLoadIntersectionChange', {
						visible: true,
						position: 'inside',
					}, {
						bubbles: false
					});					

				} else if ( this.state.visible ) {

					dispatch(el, 'lazyLoadIntersectionChange', {
						visible: true,
						position: 'inside',
					}, {
						bubbles: false
					});						

					dispatch(el, 'viewportIntersectionChange', {
						visible: true,
						position: 'inside',
					}, {
						bubbles: false
					});
					
				} else {

					dispatch(el, 'viewportIntersectionChange', {
						visible: false,
						position: viewportPosition,
					}, {
						bubbles: false
					});

				}

			} else {

				dispatch(el, 'viewportIntersectionChange', {
					visible: false,
					position: viewportPosition,
				}, {
					bubbles: false
				});
			} 
		});
	
	}


	componentDidMount(){

		const slottedElements = Array.from(this.props.baseNode.children).filter(el=>{
			const assignedSlot = el.assignedSlot;
			return el.tagName==='MEDIA-ITEM' && assignedSlot !== null && el.getAttribute('hash') === 'placeholder';
		})

		slottedElements.forEach(this.onMediaLoad);

		const pageData = this.props.pageInfo.getPageData(this.props.baseNode);

		// when navigating around 
		if( pageData.activeSlide !== undefined){
			this.waitingToGoToSlide = undefined;
			if( pageData.activeSlide >= this.len ){
				this.waitingToGoToSlide = pageData.activeSlide
			} else {
				this.goToSlide(pageData.activeSlide);
			}
		}			

		if ( !helpers.isServer ){
			subscribe(this.props.baseNode, 'lazyLoadIntersectionChange', this.onLazyLoadIntersectionChange);

			subscribe(this.props.baseNode, 'viewportIntersectionChange', this.onViewportIntersectionChange);

			// galleries are sometimes created without their children, so wait a tick before checking
			this.props.baseNode.addEventListener('pointerenter', this.onPointerEnter)
			this.props.baseNode.addEventListener('pointerleave', this.onPointerLeave)

		}
	}


	componentWillUnmount(){
		this.loadedItems.clear();
		clearTimeout(this.scrollRestore);

		if( !helpers.isServer ){

			cancelAnimationFrame(this.updateSlideTransformAnimationFrame);
			unsubscribe(this.props.baseNode, 'lazyLoadIntersectionChange', this.onLazyLoadIntersectionChange);

			unsubscribe(this.props.baseNode, 'viewportIntersectionChange', this.onViewportIntersectionChange)

			this.props.baseNode.removeEventListener('pointerenter', this.onPointerEnter)
			this.props.baseNode.removeEventListener('pointerleave', this.onPointerLeave)
		}
	}

	onViewportIntersectionChange = data =>{
		this.setState({
			visible: data.visible,
			viewportIntersection: data,
		});

	}

	onLazyLoadIntersectionChange = data =>{

		this.setState((prevState)=>{
			return {
				lazyloadable: prevState.lazyloadable || data.visible,
			}
		});		
	}

	componentDidUpdate(prevProps, prevState){

		// if we're waiting to go to a frame and the childArray got updated, check to see if it's viable
		if(
			prevProps.childArray.length!== this.props.childArray.length &&
			this.waitingToGoToSlide !== undefined
		){			
			if (this.props.childArray.length >= this.len){
				this.goToSlide(this.waitingToGoToSlide);
				this.waitingToGoToSlide = undefined;
			}
		}


		if(
			prevProps.slideshowLoad !== this.props.slideshowLoad &&
			this.state.hasLoaded &&
			this.props.slideshowLoad
		){
			this.props.slideshowLoad();
		}		

		if(
			prevState.layoutIncrement !== this.state.layoutIncrement ||
			prevState.elWidth !== this.state.elWidth ||
			prevState.slideHeightPx !== this.state.slideHeightPx ||
			prevState.hasSizes.elWidth !== this.state.hasSizes.elWidth||
			prevState.hasSizes.slideHeightPx !== this.state.hasSizes.slideHeightPx
		){
			this.updateSlideSizes();
		}


		if(prevState.cancelFadeTransition !== this.state.cancelFadeTransition && this.state.cancelFadeTransition){

			requestAnimationFrame(()=>{

				if(this.slideListRef.current){
					// force a reflow + cancel opacity transition
					Array.from(this.slideListRef.current.querySelectorAll('.slide')).forEach(slide=>{
						slide.offsetHeight
					})
				}

				this.setState({
					cancelFadeTransition: false,
				});
			});			

		}

		let endAutoplay = false;

		if(this.props.thumbnails != prevProps.thumbnails){
			endAutoplay = true;
			this.onPointerUp();
			this.onSlideEnd();
		}

		if(this.props['transition-type'] !== prevProps['transition-type'] ){

			this.setState(prevState=>{

				return {
					destinationIndex: prevState.activeIndex,
					cancelFadeTransition: true,
				}
				
			});

			endAutoplay = true;
			this.onPointerUp();
			this.onSlideEnd();
		}


		if(
			(this.props['show-captions'] !== prevProps['show-captions'] )  ||
			(this.props['mobile-show-captions'] !== prevProps['mobile-show-captions'] && this.props.isMobile ) 
		) {

			if( this.options.showCaptions  ){
				this.onSlideEnd();
				this.setState((prevState)=>{
					return {
						...prevState,
						layoutIncrement: prevState.layoutIncrement+1,
					}
				});
			} else {
				this.setState({
					captionHeightMap: [],
				});
			}

		}

		// when autoplay prop changes
		if(
			prevProps.isMobile != this.props.isMobile ||
			prevProps.autoplay != this.props.autoplay ||
			(prevProps['mobile-autoplay'] != this.props['mobile-autoplay'] && this.props.isMobile  )
		){
			endAutoplay = true;
		}

		if( prevState.pointerOver !== this.state.pointerOver && this.options.pauseOnHover){
			endAutoplay = true;
		}

		// when visibility changes
		if ( prevState.visible != this.state.visible ){
			endAutoplay = true;
		}

		// when it loads
		if( prevState.hasLoaded !== this.state.hasLoaded){
			endAutoplay = true;		
		}

		if(endAutoplay){
			clearTimeout(this.autoplayInterval);
		}

		if(
			endAutoplay
		){
			this.startAutoplay();				
		}	

		if ( this.isScrub ){
			if (!prevState.isDragging && this.state.isDragging ){
				document.body.classList.add('slideshow-scrub-dragging')
			} else if ( !this.state.isDragging && prevState.isDragging ){
				document.body.classList.remove('slideshow-scrub-dragging')
			}
		}

		if(prevProps.adminMode!== this.props.adminMode){
			if( this.state.inTransition ){
				this.onSlideEnd();	
			}
			this.setState({
				showThumbnails: false,
			})
		}

		if( this.state.activeIndex !== prevState.activeIndex){
			this.props.pageInfo.setPageData({activeSlide: this.state.activeIndex}, this.props.baseNode);

			if( this.state.activeIndex == this.state.destinationIndex){
				clearTimeout(this.showCaptionsAfterTransitionTimeout);

				this.showCaptionsAfterTransitionTimeout = setTimeout(()=>{
					this.setState({
						hideCaptionDuringTransition: false,
					});					
				}, 80);
			}
		}

		if(
			this.state.inTransition !== prevState.inTransition ||
			this.state.isPointerDown !== prevState.isPointerDown ||
			this.state.isDragging !== prevState.isDragging
		){
			if( (this.state.inTransition || this.state.isDragging ) && !this.state.hideCaptionDuringTransition ){
				clearTimeout(this.showCaptionsAfterTransitionTimeout);
				this.setState({
					hideCaptionDuringTransition: true,
				});
			}
			this.progress = 0;
			cancelAnimationFrame(this.updateSlideTransformAnimationFrame);
			this.updateSlideTransform();
		}

	}

	pause = ()=>{
		this.setState((prevState)=>{
			return {
				inTransition: false,
				destinationIndex: prevState.activeIndex
			}
		}, ()=>{
			this.onSlideEnd();
			clearTimeout(this.autoplayInterval);
		})
		
	}

	startAutoplay = ()=> {
		if(
			this.options.autoplay &&
			this.state.visible &&
			this.state.hasLoaded &&
			!this.options.showThumbnails &&
			((this.options.pauseOnHover && !this.state.pointerOver) || !this.options.pauseOnHover)
		){
			clearInterval(this.autoplayInterval);
			this.autoplayInterval = setTimeout(this.nextSlide, this.options.autoPlayDelay*1000)
		}

	}

	onItemResize = (e, el, index)=>{


		this.setState((prevState)=>{

			const caption = Array.from(el.children).find(el=> el.getAttribute('slot') === 'caption' );
			const newCaptionHeightMap = [...prevState.captionHeightMap];
			let height = prevState.captionHeightMap[index];
			let newHeight = 0;

			if( caption && this.options.showCaptions ){
				const style = window.getComputedStyle(caption);
				const position = style.getPropertyValue('position');
				if( position === 'fixed' || position === 'absolute' ){
					newHeight = 0;
				} else {
					const boxSizing = style.getPropertyValue('box-sizing');

					if( boxSizing === 'content-box'){

						newHeight= parseFloat(style.getPropertyValue('height')) || 0 ;
						newHeight+= parseFloat(style.getPropertyValue('margin-top')) || 0 ;
						newHeight+= parseFloat(style.getPropertyValue('margin-bottom')) || 0 ;
						newHeight+= parseFloat(style.getPropertyValue('padding-top')) || 0 ;
						newHeight+= parseFloat(style.getPropertyValue('padding-bottom')) || 0 ;
						newHeight+= parseFloat(style.getPropertyValue('border-top')) || 0 ;
						newHeight+= parseFloat(style.getPropertyValue('border-bottom')) || 0 ;	
											
					} else {

						newHeight= parseFloat(style.getPropertyValue('height')) || 0 ;
						newHeight+= parseFloat(style.getPropertyValue('margin-top')) || 0 ;
						newHeight+= parseFloat(style.getPropertyValue('margin-bottom')) || 0 ;
						
					}
				}

			} else {
				newHeight = 0;
			}

			if( newHeight !== height){
				newCaptionHeightMap[index] = newHeight;
				return {
					captionHeightMap: newCaptionHeightMap,
					layoutIncrement: prevState.layoutIncrement+1
				}
			} else {
				return {
					layoutIncrement: prevState.layoutIncrement+1
				}				
			}


		})
	}

	updateSlideSizes = () =>{

		if(this.len == 0){
			return;
		}		

		const {
			hasSizes
		} = this.state;

		let {
			elWidth,
		} = this.state;

		if( !hasSizes.elWidth && this.cachedElWidth ){
			elWidth = this.cachedElWidth;
		}


		const slideHeights = this.mediaItems.map((el, index)=> {
			
			const size = el._size || {
				width: 0,
				height: 0,
				mediaSize: {
					width: 0,
					height: 0,
					padSize: 0,
				},
				mediaItemSize: {
					width: 0,
					height: 0,
					padSize: 0,
				}					
			};

			let width = size.width;
			let height = size.height;

			let measuredHeight = height;
			
			if( typeof width == "string" && width.indexOf('%') > -1 ){

				// make sure heights aren't percentages
				measuredHeight = parseFloat(height)+size.mediaItemSize.padSize + size.mediaSize.padSize || 150+size.mediaItemSize.padSize + size.mediaSize.padSize;
			} else {
				const imageRatio = height/width;

				if(size.mediaSize.padSize > 0 ){
					const borderRatio = ((elWidth-size.mediaSize.padSize)*imageRatio+size.mediaSize.padSize)/(elWidth)					
					measuredHeight = (elWidth-(size.mediaItemSize.padSize)) * borderRatio + size.mediaItemSize.padSize
				} else {
					measuredHeight = (elWidth-size.mediaItemSize.padSize) * imageRatio + size.mediaItemSize.padSize					
				}

			}

			return measuredHeight
			
		});

		const tallestHeight = _.maxBy(slideHeights, (slideSize) => slideSize ? slideSize : 0) || 0;


		const tallestSlide = {
			index: slideHeights.indexOf(tallestHeight),
			height: tallestHeight
		}

		this.setState({
			tallestSlide
		})
	
	}	

	onResize = (key, size) =>{

		this.setState(prevState=>{

			return {
				hasSizes: {
					...prevState.hasSizes,
					[key]: true
				},
				[key]: size
			}
		})
	}

	handleKeyDown =(e) =>{

		switch(e.keyCode){
			case 39:
				e.preventDefault();
				this.nextSlide();
				break;

			case 37:
				e.preventDefault();
				this.prevSlide();
				break;

		}

	}

	onPointerEnter =(e)=>{
		this.setState({pointerOver: true})
	}
	onPointerLeave =(e)=>{
		this.setState({pointerOver: false})
	}	


	// tracking frame-by-frame rather than on every event smoothes things out a bit
	// animation starts on scrub mousedown and doesn't stop until animation has run its course
	scrubAnimate = () => {
		const {
			elWidth,
		} = this.state;
		const threshold = SCRUB_MOVE_THRESHOLD === 0 ? 1 / this.len : SCRUB_MOVE_THRESHOLD;

		let xPoint = this.pointerX;

		// get pointerFrameDeltaX from mouse movement
		if ( this.state.isPointerDown){

			this.scrubCounter = 0;
			const newFrameDeltaX = this.lastPointerX - this.pointerX;

			// only track last delta if the direction is nonzero
			// not used for calculations, only for reference of last direction
			if ( this.pointerFrameDeltaX != 0 ){
				this.lastPointerFrameDeltaX = this.pointerFrameDeltaX;
			}

			this.pointerFrameDeltaX = newFrameDeltaX;

			// detect direction change
			// reset reference point to change
			if ( this.lastPointerFrameDeltaX > 0 && this.pointerFrameDeltaX < 0){
				this.firstPointerX = this.pointerX;
			} else if (this.lastPointerFrameDeltaX < 0 && this.pointerFrameDeltaX > 0){
				this.firstPointerX = this.pointerX;
			}


		// otherwise, make it up from animation inertia
		} else {

			if ( this.state.scrubStatus === SCRUB_PREV ){

				this.scrubCounter = 0;
				this.pointerFrameDeltaX = this.pointerFrameDeltaX + -SCRUB_SPEED_ACCEL;

			} else if ( this.state.scrubStatus === SCRUB_NEXT ) {

				this.scrubCounter = 0;
				this.pointerFrameDeltaX = this.pointerFrameDeltaX + SCRUB_SPEED_ACCEL;

			} else if ( this.state.scrubStatus === SCRUB_DECAYING ) {
				if ( this.pointerFrameDeltaX < 0){
					this.pointerFrameDeltaX = Math.min(0, this.pointerFrameDeltaX + SCRUB_SPEED_DECAY);
				} else {
					this.pointerFrameDeltaX = Math.max(0, this.pointerFrameDeltaX + -SCRUB_SPEED_DECAY);
				}

			}

		}

		if ( Math.abs(this.pointerFrameDeltaX) > (SCRUB_TOP_SPEED*threshold)*elWidth ){
			if ( this.pointerFrameDeltaX > 0){
				this.pointerFrameDeltaX = (SCRUB_TOP_SPEED*threshold)*elWidth;
			} else {
				this.pointerFrameDeltaX = -(SCRUB_TOP_SPEED*threshold)*elWidth;
			}
		}

		if (this.state.isPointerDown){

			const unlimitedXDelta = this.lastPointerX - this.pointerX;
			const limitedXDelta = this.lastPointerX - (this.lastPointerX+this.pointerFrameDeltaX);

			// drag the reference point around while dragging so we're never progressing faster than range
			this.firstPointerX = this.firstPointerX-(unlimitedXDelta-limitedXDelta)
			
		} else {

			// otherwise, cue up next pointerX by just adding the framedelta
			this.pointerX = this.lastPointerX + this.pointerFrameDeltaX;
			xPoint = this.pointerX;
		}


		this.lastPointerX = this.pointerX;

		this.xDrag = xPoint - this.firstPointerX;

		const percentMoved = Math.abs(this.xDrag/elWidth);

		if ( percentMoved > threshold && this.len > 1 ) {

			const newIndex = this.xDrag > 0 ? (this.state.activeIndex+1)%this.len : (this.state.activeIndex+-1+this.len)%this.len;
			// set a large xDrag to indicate we've changed slides
			this.setState({
				destinationIndex: newIndex,
				activeIndex: newIndex,
			});
			this.xDrag = 9e9;

			this.scrubCounter = 0;
			this.firstPointerX = this.pointerX;
		}

		clearTimeout(this.autoplayInterval);

		if ( this.state.scrubStatus == SCRUB_DECAYING && this.scrubCounter >= 30 && !this.state.isPointerDown ){

			this.setState({scrubAnimating: false});
			this.scrubCounter =0;
			if ( this.options.autoplay){
				// factor that final second into timeout
				this.autoplayInterval = setTimeout(this.nextSlide, Math.max(100, this.options.autoPlayDelay*1000-1000));

			}

		} else {

			this.scrubCounter++;

			this.scrubAnimationRequest = requestAnimationFrame(this.scrubAnimate)

		}


	}

	startScrubDelta = (e, scrubDirection) => {

		if ( e ){

			if ( e.button == 2){
				return;
			}
			e.preventDefault();
		}

		if ( !this.isScrub || this.len < 2){
			return
		}


		this.setState({scrubStatus: scrubDirection})
		this.scrubCounter = 0;

		this.firstPointerX = this.lastPointerX = this.pointerX = 0;
		this.lastPointerFrameDeltaX = this.pointerFrameDeltaX = 0;

		this.xDrag = 0;
		this.setState({
			scrubAnimating: true,
		})

		cancelAnimationFrame(this.scrubAnimationRequest);
		this.scrubAnimationRequest = requestAnimationFrame(this.scrubAnimate);

		window.addEventListener('pointerup', this.onScrubPointerUp);
		window.addEventListener('pointercancel', this.onScrubPointerUp);			
	
	}

	onScrubPointerUp =(e)=>{
		this.setState({
			scrubStatus: SCRUB_DECAYING,
		})
		window.removeEventListener('pointerup', this.onScrubPointerUp);
		window.removeEventListener('pointercancel', this.onScrubPointerUp);	
	}

	onClickCapture =(e)=>{
		// prevent click of <a> while dragging
		if (this.cancelClick ) {
			e.preventDefault();
		} 
	}



	onPointerDown = (e) => {
		
		if(e.defaultPrevented){
			return;
		}

		if( e.target.closest('.caption') ){
			return;
		}
		
		if ( !this.slideListRef.current || e.button == 2){
			return;
		}
		this.cancelClick = false;

		clearTimeout(this.autoplayInterval);

		this.firstPointerX = this.lastPointerX = this.pointerX = e.clientX;
		this.pointerFrameDeltaX =0;
		this.xDrag = 0;
		this.initialXTransform = this.isSlide ? (this.xTransform) : 0;


		this.setState((prevState)=>{

			return{
				isPointerDown: true,
				initialActiveIndex: prevState.activeIndex,
			}
		})

		window.cancelAnimationFrame(this.scrubAnimationRequest);

		if ( this.isScrub ){
			this.scrubAnimationRequest = window.requestAnimationFrame(this.scrubAnimate)			
		}

		window.addEventListener('pointermove', this.onWindowPointerMove);
		window.addEventListener('pointerup', this.onPointerUp);
		window.addEventListener('pointercancel', this.onPointerUp);	
	}

	getSlideListStyles = ()=>{
		
		let slideListTransform = `translateX(0)`;
		let captionTransform = 'translateX(0)';

		if ( this.isSlide ){

			let transform = Math.round((this.state.elWidth*(-1+(1-this.lazyloadPrev)) + this.xTransform)*2)/2;
			slideListTransform = `translateX(${transform}px)`;
			captionTransform = `translateX(${-Math.round(this.xTransform*2)/2}px)`;
		}

		return {
			'--slideshow-caption-transform': captionTransform,
			transform: slideListTransform,
		}
	}

	updateSlideTransform= (timestamp)=>{
		const {
			destinationIndex,
			activeIndex,
			elWidth,
		} = this.state;

		let willChange = '';

		if(this.progress === undefined){
			this.progress = 0;
		}		


		if( this.isSlide && (this.state.inTransition || this.state.isPointerDown || this.state.isDragging) ){

			willChange = 'transform';

			let xTransform = this.xDrag+this.initialXTransform;

			let easedValue = 0;
			let startPosition = xTransform;
			let endPosition = elWidth;

			if ( destinationIndex > activeIndex ){
				endPosition = -elWidth;
			} else if (destinationIndex < activeIndex) {
				endPosition = elWidth;
			} else {
				endPosition = 0;
			}


			let delta = 0;

			if( !timestamp || timestamp == 0){
				timestamp = 0;
				this.lastTimestamp = 0;
				delta = 0;
			} 

			if( this.lastTimestamp == 0 ){
				this.lastTimestamp = timestamp || 0;
			}

			delta = timestamp - this.lastTimestamp;
			this.lastTimestamp = timestamp;
			

			if( this.state.isDragging || this.state.isPointerDown ){

				if( this.state.isDragging ){

					if( this.dragPxPerSecond === undefined){
						this.dragPxPerSecond = 0;
					}

					if( this.lastDragX === undefined){
						this.lastDragX = this.pointerX;
					}

					let dragPxPerSecond = (this.pointerX - this.lastDragX)*(delta)
					if( dragPxPerSecond != 0 ){

						if( dragPxPerSecond > 0 && this.dragPxPerSecond > 0 ){

							// if we're decelerating, slowly ramp it down
							if( dragPxPerSecond < this.dragPxPerSecond ){
								dragPxPerSecond = this.dragPxPerSecond *.95 + dragPxPerSecond*.05;
							}

						} else if ( dragPxPerSecond < 0 & this.dragPxPerSecond < 0 ){
							// if we're decelerating, slowly ramp it down
							if( dragPxPerSecond > this.dragPxPerSecond ){
								dragPxPerSecond = this.dragPxPerSecond *.95 + dragPxPerSecond*.05;
							}

						}
						this.dragPxPerSecond = dragPxPerSecond;

					} 
					this.lastDragX = this.pointerX;

				}

				this.progress = 0;

			} else {

		
				if( !this.state.isPointerDown) {
					this.progress = this.progress+delta;						
				}

				if(this.dragPxPerSecond!== undefined && this.dragPxPerSecond !== 0){

					if( this.dragPxPerSecond < 0 ){
						this.dragPxPerSecond = Math.min(-800, this.dragPxPerSecond);
					} else {
						this.dragPxPerSecond = Math.max(800, this.dragPxPerSecond);
					}

					// this is the distance the slide has yet to travel to its destination position
					// start by measuring from left edge, default for going previous
					// this is used to set a shorter transition time when releasing the slide part-way
					const secondsToCompleteTraversal = (elWidth/Math.abs(this.dragPxPerSecond))
					easedValue = helpers.easeOutCubic(this.progress/(Math.min(secondsToCompleteTraversal, this.options.speed) *1000 )  );
				} else {
					easedValue = helpers.easeInOutSine(this.progress/(this.options.speed *1000));
				}
		
				xTransform = (easedValue*endPosition) + (1-easedValue)*startPosition

			}

			xTransform = Math.min( Math.max(-elWidth, xTransform), elWidth);

			this.xTransform = xTransform
		

			if( easedValue >= 1   && !(this.state.isPointerDown || this.state.isDragging ) ){
				this.dragPxPerSecond = undefined;
				this.lastDragX = undefined;	
				this.onSlideEnd();
			} else {
				this.updateSlideTransformAnimationFrame = requestAnimationFrame(this.updateSlideTransform);				
			}

		} else if ( this.isScrub) {

			this.xTransform = 0;
			this.progress = 0;			

		} else if ( this.isFade) {
			this.xTransform = 0;
			this.progress = 0;	
		}

		const slideListStyles = this.getSlideListStyles();

		if( this.slideListRef.current && slideListStyles.transform !== this.slideListRef.current.style.transform ){

			this.slideListRef.current.style.transform = slideListStyles.transform;

			if( this.styleRef.current){

				this.styleRef.current.sheet?.cssRules?.[0]?.style.setProperty('--slideshow-caption-transform', (slideListStyles['--slideshow-caption-transform'] || 'translateX(0px)'));

				if( helpers.isSafari() ){
					Array.from(this.props.baseNode.children).forEach((child)=>{
						if( child.nodeName === 'MEDIA-ITEM' && child.assignedSlot ){
							child._forceRedraw?.();
						}
					});
				}
		
			}

			this.slideListRef.current.style.willChange = willChange;
		} 

	}

	onPointerUp =(e)=> {

		window.removeEventListener('pointercancel', this.onPointerUp);				
		window.removeEventListener('pointerup', this.onPointerUp);		
		window.removeEventListener('pointermove', this.onWindowPointerMove);

		clearTimeout(this.autoplayInterval);
		cancelAnimationFrame(this.scrubPointerAnimationRequest);

		let clickedOnLink = false;
		let clickedOnZoomable = false;
		let clickedOnDefaultVideo = false;

		if( e){

			let clickedOnMediaPart = Array.from(e.composedPath()).some(el=>el.getAttribute?.('part')?.includes('media'));

			// check to see if media item is a video item
			// if we clicked on a video element
			if( clickedOnMediaPart && e.target.tagName === 'MEDIA-ITEM' && e.target.getAttribute('browser-default') == 'true' ){
				const fileType = e.target.getFileType?.() ?? '';
				if(
					(fileType.startsWith('vimeo') || fileType.startsWith('youtube') || fileType == 'video') 
				){
					clickedOnDefaultVideo = true;
				}
				
			}

			if( e.target?.closest('a, [href]') ){
				clickedOnLink =true;
			}

			if( e.target?.closest('media-item.zoomable') && clickedOnMediaPart ){
				clickedOnZoomable = true;
			}

			if(!clickedOnLink && !clickedOnZoomable){
				e?.preventDefault();
			}
		}

		// ios doesn't dispatch clicks if any part of the pointer event is canceled - dispatch a click at the end of it and let
		// the clickCapture logic take care of it
		if ( e?.pointerType === 'touch' && !clickedOnLink && e.type !== 'pointercancel' && e.target.nodeName ==='MEDIA-ITEM'){
			e.target?.shadowRoot?.querySelector('.sizing-frame')?.click();
		}

		let nextSlideAfterUpdate = false;
		let endFadeTransitionAfterUpdate = false;
		// the default for any pointer-up is to go to the next slide
		// except when dragging or if the pointer-up triggers a snap-to transition
		this.setState((prevState) => {

			let {
				elWidth,
				scrubStatus,
				isPointerDown,
				isDragging,
				inTransition,
				finishingTransition,
				scrubAnimating,
				activeIndex,
				destinationIndex,
			} = prevState;



			// no dragging happened and we clicked on an interactive item. cancel the progression
			if ( !(prevState.isDragging) && (clickedOnLink || clickedOnZoomable || clickedOnDefaultVideo) ) {
				nextSlideAfterUpdate = false;

			} else {

				if( this.isScrub){

					inTransition = false;
					finishingTransition = false;

					// if dragging, we start the scrub-flick animation
					if( prevState.isDragging ){

						scrubAnimating = true;

					// otherwise, we freeze the animation and go to the next slide
					} else if(Math.abs(this.xDrag) < 8 ){

						scrubAnimating = false;
						nextSlideAfterUpdate = true;

					}

				} else if ( this.isSlide  && this.options.speed != 0 ) {

					if( prevState.isDragging ){

						finishingTransition = true;
						inTransition = true;
						nextSlideAfterUpdate = false;

					// otherwise, we freeze the animation and go to the next slide
					} else {

						//  we interrupted a transition in-progress.
						/// let it complete the transition quickly			
						if( prevState.inTransition || prevState.finishingTransition ){

							nextSlideAfterUpdate = false;
							finishingTransition = true;
							inTransition = true;

						// if no transition, go to the next slide
						} else {
							nextSlideAfterUpdate = true;
							finishingTransition = false;
							inTransition = false;
						}

					}


				} else if ( this.isFade && this.options.speed != 0 ) {

					if( prevState.isDragging ){

						nextSlideAfterUpdate = false;

					// otherwise, we freeze the animation and go to the next slide
					} else {

						nextSlideAfterUpdate = true;

					}



				} else if ( this.options.speed == 0 ){

					if( prevState.isDragging ){
						inTransition = false;
						nextSlideAfterUpdate = false;

					} else {
						inTransition = false;
						nextSlideAfterUpdate = true;

					}
					activeIndex = destinationIndex;

				
				}

			}
	
			return {
				elWidth,
				scrubStatus: SCRUB_DECAYING,
				isPointerDown: false,
				isDragging: false,
				inTransition,
				destinationIndex,
				activeIndex,
				scrubAnimating,
			}


		}, ()=>{

			if( this.isFade && endFadeTransitionAfterUpdate ){
				this.setState({
					cancelFadeTransition: true
				});
			}

			if( nextSlideAfterUpdate ){
				this.onSlideEnd();				
				this.nextSlide();
			}


		})


	}

	onWindowPointerMove =(e)=> {

		this.pointerX = e.clientX;
		this.xDrag = this.pointerX - this.firstPointerX;

		this.setState((prevState) =>{

			let {
				activeIndex,
				destinationIndex,
				isDragging,
				initialActiveIndex,
			} = prevState;

			const percentMoved = Math.abs(this.xDrag/prevState.elWidth)

			const nextSlidePosition = prevState.elWidth * (-2+(1-this.lazyloadNext));
			const prevSlidePosition = prevState.elWidth * (1-this.lazyloadPrev);

			if (!prevState.isDragging && prevState.isPointerDown && Math.abs(this.xDrag) > DRAG_THRESHOLD ) {

				isDragging = true;


				if(this.isSlide && prevState.inTransition && this.len > 1 ){

					// if we're in-progress in a transition, flip
					// forward / back  by one so the drag doesn't immediately
					// come up on the hard 1-index limit limit

					if( prevState.destinationIndex > prevState.activeIndex && this.xDrag < 0){

						initialActiveIndex++;
						activeIndex++;
						destinationIndex++;
						this.xTransform = this.xTransform + prevState.elWidth;
						this.initialXTransform = this.initialXTransform + prevState.elWidth;
					} else if( prevState.destinationIndex < prevState.activeIndex && this.xDrag > 0 ) {
						initialActiveIndex--;
						activeIndex--;
						destinationIndex--;
						this.xTransform = this.xTransform - prevState.elWidth;
						this.initialXTransform = this.initialXTransform - prevState.elWidth;
					}

					initialActiveIndex = (activeIndex+this.len*3)%this.len;
					activeIndex = (activeIndex+this.len*3)%this.len;
					destinationIndex = (destinationIndex+this.len*3)%this.len;

				}



				this.cancelClick = true;
			}

			if( isDragging ){

				const nextDestination = this.xDrag < 0 ? (activeIndex+1) : (activeIndex-1)

				if (
					percentMoved > SLIDE_TRANSITION_THRESHOLD && this.len > 1
				) {

					if ( (Math.abs(nextDestination - prevState.initialActiveIndex) < 2 || this.isScrub) ) {
						destinationIndex = nextDestination
					}

				} else if ( !this.isScrub  ){
					destinationIndex = prevState.initialActiveIndex
				}

				if( destinationIndex < -1){
					destinationIndex = destinationIndex+this.len
				}				
			}

			if(
				prevState.activeIndex !== activeIndex ||
				prevState.destinationIndex !== destinationIndex ||
				prevState.isDragging !== isDragging ||
				prevState.initialActiveIndex !== initialActiveIndex
			) {

				return {
					...prevState,
					activeIndex,
					destinationIndex,
					initialActiveIndex,
					isDragging,
				};

			} else {

				// update outside of render cycle
				const slideListStyles = this.getSlideListStyles();

				if( this.slideListRef.current ){

					this.slideListRef.current.style.transform = slideListStyles.transform;

					if( this.styleRef.current){
						this.styleRef.current.sheet?.cssRules?.[0]?.style.setProperty('--slideshow-caption-transform', (slideListStyles['--slideshow-caption-transform'] || 'translateX(0px)'));
					}
				}

				return null;					
				
			}
		})

	}

	nextSlide = (e) => {

		if ( e ){
			if( e.defaultPrevented || e.button == 2 ){
				return;
			}
			
			e.stopPropagation();
			e.preventDefault();
		}


		if (this.len < 2){
			return;
		}


		if( this.state.inTransition ){
			this.onSlideEnd();
			return;
		}

		cancelAnimationFrame(this.scrubAnimate);

		this.setState((prevState) => {
			const newState = {...prevState}
			const nextIndex = prevState.activeIndex+1;

			newState.inTransition = (this.isScrub || this.options.speed == 0) ? false : true;
			this.xTransform = 0;
			this.initialXTransform = 0;

			if ( this.isScrub){

				newState.destinationIndex = nextIndex;
				newState.scrubStatus = SCRUB_DECAYING

			} else {
				newState.destinationIndex = nextIndex;
			}
			this.xDrag = 0;

			return newState;
		});


		if(this.options.speed == 0 || this.isScrub){
			this.onSlideEnd();
		}

	}

	prevSlide = (e) => {
		if ( e ){
			if( e.defaultPrevented || e.button == 2 ){
				return;
			}
			
			e.stopPropagation();
			e.preventDefault();
		}
		if (this.len < 2){
			return;
		}

		if( this.state.inTransition ){
			this.onSlideEnd();
			return;
		}

		cancelAnimationFrame(this.scrubAnimate);

		this.setState((prevState) => {
			const newState = {...prevState}
			let prevIndex = prevState.activeIndex-1;
			if( prevIndex < -1){
				prevIndex = prevIndex+this.len
			}
			
			newState.inTransition = (this.isScrub || this.options.speed ==0 ) ? false : true;

			this.xTransform = 0;
			this.initialXTransform = 0;

			if ( this.isScrub){

				newState.destinationIndex = prevIndex;					
				newState.scrubStatus = SCRUB_DECAYING

			} else {
				newState.destinationIndex = prevIndex;
			}
			this.xDrag = 0;

			return newState;
		});

		if(this.options.speed == 0 || this.isScrub){
			this.onSlideEnd();
		}		
	}

	onThumbnailClick = (e)=>{

		if( this.props.adminMode){
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		const targetMediaItem = e?.target?.closest?.('media-item') || null;

		if( targetMediaItem){

			const destinationIndex = Array.from(this.props.baseNode.children).indexOf(targetMediaItem);

			this.setState({
				destinationIndex: destinationIndex,
				activeIndex: destinationIndex,
			}, this.hideThumbnails);

		}
	}

	onSlideEnd =() => {
		clearTimeout(this.autoplayInterval);

		if( this.isFade && this.slideListRef.current && this.state.inTransition){
			this.setState({
				cancelFadeTransition: true,
			});		
		}

		this.setState((prevState) => {

			// newState.xTransform = 0;
			this.xTransform = 0;

			let destinationIndex = (prevState.destinationIndex+this.len+this.len+this.len)%this.len;
			let activeIndex = destinationIndex;

			return {
				...prevState,
				activeIndex,
				destinationIndex,
				inTransition:  false,

			};
		}, ()=>{

			if( this.isSlide && this.slideListRef.current){
				this.slideListRef.current.style.willChange = '';
			}

			if(this.props.afterSlideChange){

				let slot = this.props.baseNode.shadowRoot?.querySelector('.active-slide slot');
				const activeItem = slot?.assignedElements()?.[0]|| null

				this.props.afterSlideChange(this.state.activeIndex, activeItem )
			}
		});

		this.startAutoplay();
	}

	onSlideOpacityTransitionStart =(e , relativeIndex)=> {
		if ( e.propertyName === 'opacity' && relativeIndex == 0 && e.target.classList.contains('slide')  ){

		}
	}

	onSlideOpacityTransitionEnd =(e , relativeIndex)=> {
		if ( e.propertyName === 'opacity' && relativeIndex == 0 && e.target.classList.contains('slide')  ){
			this.onSlideEnd();
		}
	}

	getWrapAroundDistanceFromIndex=(index, secondIndex, length) => {
		// use triangle wave to calculate adjacent slides looping front-back
		// my math is kind of shaky so bear with it
		const wavelength = length*.5;

		let dist = wavelength - Math.abs((index+-secondIndex) % (2*wavelength) - wavelength);
		if ( dist < -wavelength && dist < 0){
			dist = dist + length
		}
		return dist;
	}

	makeMediaItemAttributes = () =>{

		if(this.len == 0){
			return;
		}

		const {
			showThumbnails,			
			elWidth,
			slideHeightPx,
			activeIndex,
			actualCardSize,
			destinationIndex
		} = this.state;

		const {
			shuffle,
			limitBy,
			scale,
			adjustForCaptions,
		} = this.options
		const useShuffleIndexing = shuffle && !showThumbnails;

		const mediaItemAttributes = [];

		for(let i = 0; i < this.len; i++){
			
			let index = (i+this.len)%this.len;
			let slotIndex = index;
			if(useShuffleIndexing){
				slotIndex = this.shuffleList[index];
			}

			let slot = `slot-${slotIndex}`;

			let isAdjacent = Math.abs(this.getWrapAroundDistanceFromIndex((slotIndex+this.len)%this.len, activeIndex, this.len)) < 3
			let attribs = {
				// attributes
				'itemDragStart': this.onDragStart,
				itemResize: (e, el)=> { this.onItemResize(e, el , slotIndex) },
				drag: false,
				rotation: '0',
				scale: '100',
				'limit-by': 'width',
				isAdjacent: isAdjacent,

				// special - used to trigger visibility manually on media-items
				// visibility: isAdjacent,
				visibility: activeIndex == index,

				slot: showThumbnails ? 'thumbnails' : slot,

			}

			mediaItemAttributes.push(attribs);

		}

		return mediaItemAttributes

	}

	onDragStart = (e)=>{

		if( !this.state.showThumbnails){
			e.preventDefault();
			e.stopPropagation();			
		}

	}

	onMediaLoad=(mediaItem)=>{

		const slottedElements = Array.from(this.props.baseNode.children).filter(el=>{
			const assignedSlot = el.assignedSlot;
			return el.tagName==='MEDIA-ITEM' && assignedSlot !== null;
		})


		if( !this.loadedItems.has(mediaItem) ){
			this.loadedItems.add(mediaItem);
		}

		var loadTarget = this.isFade ? Math.min(slottedElements.length, 3) : slottedElements.length;

		if(	this.loadedItems.size >= loadTarget && !this.state.hasLoaded ){
			this.setState({hasLoaded: true});

			setTimeout(()=>{
				this.setState({initialShowNavigation: false})
			}, (this.props?.['navigation-hide-delay'] * 1000 ) || 1500 );

			if(this.props.slideshowLoad){
				this.props.slideshowLoad();
			}

		}

	}


	renderSlides = () =>{

		const {
			elWidth,
			destinationIndex,
			activeIndex,
			inTransition,
			showThumbnails,
		} = this.state;

		const {
			xDrag,
			initialXTransform,
		} = this;


/**
		[1][2][3][4]


**/

		let slides = [];

		const xTrans = initialXTransform%elWidth + xDrag;	
		let shiftToLeft = (xTrans > 0 || destinationIndex < activeIndex) && this.isSlide && this.len == 2;


		// we are only slotting a certain number of items at a time...
		// but if that length is lower than the lazyload threshold, slot all of them
		// slide and fade render 3, scrub renders more
		for (let i = -this.lazyloadPrev; i < this.lazyloadNext+1; i++ ){

			let relativeIndex = i;
			/*
				we are rendering 3 slides. j ranges from -1 , 0 , 1
				-1 is the one previous to the activeIndex, 1 is next

				when we have two slides and are moving left, we have to set up a sleight of hand
				where we jump back once but then shift forward while rendering the slide list
			*/
			if(shiftToLeft){
				relativeIndex = relativeIndex + -1;
			}

			/*
				we get the actual slot through modulo
				if we have 30 slides, and active is 5, then slot for -1 is 4
				if we have 3 slides, and active is 0, then slot for -1 is 2
			*/

			let index = (relativeIndex + activeIndex+this.len*2)%this.len;
			let slot = 'slot-'+index;
			let isActiveSlide = false;
			let isDestinationSlide = false;

			const slideAttributes = {};
			slideAttributes.className = `slide ${this.options.type}-transition`;

			// if the card is the one being transitioned-to
			if (
				(destinationIndex+this.len)%this.len == index 
			){
				isDestinationSlide = true;
				slideAttributes.className+=' destination-slide';
			}

			// if the card is the one currently active
			if(
				(activeIndex+this.len)%this.len == index 
			){
				isActiveSlide = true;
				slideAttributes.className+=' active-slide';
			}

			slideAttributes.part = 'slide'

			slideAttributes.style = {};
			if ( !showThumbnails){
				slideAttributes.style = {
					'width': `${elWidth}px`,
					'transform': shiftToLeft ? 'translateX('+-elWidth+'px)' : null
				};				
			}
			if ( this.isFade ){
				slideAttributes.onTransitionStart = (e)=> {  this.onSlideOpacityTransitionStart(e, relativeIndex) };				
				slideAttributes.onTransitionEnd = (e)=> {  this.onSlideOpacityTransitionEnd(e, relativeIndex) };
			}

			if( relativeIndex == -1  ){
				slideAttributes.part += ' '+this.options.type+'-slide-previous'
			}	
			if( relativeIndex  == 1  ){
				slideAttributes.part += ' '+this.options.type+'-slide-next'
			}

			if (
				relativeIndex >= -1 && relativeIndex <= 1
			){
				slideAttributes.className+=' adjacent-slide';
				slideAttributes.part += ' '+this.options.type+'-slide-adjacent'
			} else {
				slideAttributes.className+=' non-adjacent-slide';				
			}

			slideAttributes.slot = slot;
			slides.push(<div {...slideAttributes} key={'slot_'+slot} >
				<slot name={slot} />
			</div>)				

		}

		return slides;
		
	}


	goToSlide = (destinationIndex)=>{

		destinationIndex = destinationIndex%this.len || 0;

		if( isNaN(destinationIndex) || this.state.destinationIndex === destinationIndex ){
			return
		}

		this.setState({
			showThumbnails: false,
			destinationIndex: destinationIndex,
			activeIndex: destinationIndex,
			inTransition: false,
		}, ()=>{
			this.updateSlideTransform();		  
		})


	}

	getActiveSlide = ()=>{
		let slot = this.props.baseNode.shadowRoot?.querySelector('.active-slide slot');
		const activeItem = slot?.assignedElements()?.[0]|| null
		return [this.state.activeIndex, activeItem]

	}

	showThumbnails = ()=>{
		let scrollPos = this.props.scrollContext.getScrollPosition().y
		this.setState({
			showThumbnails: true,
			inTransition: false,
		}, ()=>{
			this.scrollRestore = setTimeout(()=>{
				this.props.scrollContext.updateScrollPosition({x: null, y:scrollPos})
			}, 60)
			this.props.baseNode.dispatchEvent(new CustomEvent('show-thumbnails', {
				composed: true,
				bubbles: false
			}));
		});
	}

	hideThumbnails = ()=>{

		let scrollPos = this.props.scrollContext.getScrollPosition().y

		let sel = window.getSelection();

		if( sel.rangeCount > 0){
			var selectionIntersectsSlideshow = window.getSelection()?.getRangeAt?.(0)?.intersectsNode(this.props.baseNode);

			if( selectionIntersectsSlideshow){
				window.getSelection()?.removeAllRanges();
			}			
		}

		this.setState((prevState)=>{
			return {
				destinationIndex: prevState.activeIndex,
				showThumbnails: false,
				inTransition: false
			}
		}, ()=>{

			this.scrollRestore = setTimeout(()=>{
				this.props.scrollContext.updateScrollPosition({x: null, y:scrollPos})
			}, 60)
			this.props.baseNode.dispatchEvent(new CustomEvent('hide-thumbnails', {
				composed: true,
				bubbles: false
			}));
		})
	}

}

Slideshow.defaultProps = {
	'show-tags': true,
	'show-title': true,
	'thumbnail-index': undefined,
}

const ConnectedSlideshow = withPageInfo(withThumbnailContent(connect(
    (state, ownProps) => {
        return {
            isMobile: state.frontendState.isMobile,        	
            adminMode: state.frontendState.adminMode,
			usesScrollAnimation: state.siteDesign.images.scroll_animation,
        };
    }
)(Slideshow)));

register(ConnectedSlideshow, 'gallery-slideshow', [
	'thumbnail-index',
	'thumbnail-index-metadata',
	'links-filter-index',
	'show-tags',
	'show-title',



	// events
	'slideshowLoad',
	'afterSlideChange', 

	// attributes
	'thumbnails',
	'transition-type',
	'autoplay',
	'autoplay-delay',
	'transition-speed',
	'navigation',
	'shuffle',
	'height-limit',
	'auto-height',
	'vertical-align',
	'horizontal-align',
	'adjust-for-captions',
	'show-captions',
	'pause-on-hover',
	'disable-scroll-animation',

	// mobile attributes
	// attributes
	'mobile-adjust-for-captions',
	'mobile-thumbnails',
	'mobile-transition-type',
	'mobile-autoplay',
	'mobile-autoplay-delay',
	'mobile-transition-speed',
	'mobile-navigation',
	'mobile-shuffle',
	'mobile-height-limit',
	'mobile-auto-height',
	'mobile-vertical-align',
	'mobile-horizontal-align',
	'mobile-show-captions',
	'mobile-pause-on-hover'
]) 


export {layoutData};
export default Slideshow
